import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { UserProfile } from '../user/UserProfile'
import { Button } from '../section-components/Button'
import styles from './Banner.module.scss'
import { decodeHtml } from '../../util/decodeHTML'
import logo from '../../assets/JNJOneAccess-logo-red.svg'
import { formatDate } from '../user/UserInfo'

function getInitials(userInfo) {
  if (!userInfo?.firstName) {
    return 'NN'
  }
  if (!userInfo.lastName) {
    return userInfo.firstName[0]
  }
  return `${userInfo.firstName[0]}${userInfo.lastName[0]}`
}

function BannerDesktop({
  userInfo, links, showUserProfile, signInButtonText, onSignIn, dropdownMenuLinks, errorBar
}) {
  const { t } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen)
  const location = useLocation()
  const isCurrent = (path) => location.pathname === path
  let formattedLoginDate
  const divRef = useRef()
  const initials = getInitials(userInfo)

  if (userInfo && userInfo.lastLogin) {
    try {
      formattedLoginDate = formatDate(userInfo.lastLogin)
    } catch {
      formattedLoginDate = ''
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div data-testid="banner">
      <div className={styles.banner}>
        <Link to="/home"><img src={logo} alt="J&J One Access" className={styles.logo} /> </Link>
        <div className={styles.navigationLinks}>
          {links.map((link) => (
            <Link
              key={link.label}
              to={link.path}
              className={isCurrent(link.path) ? styles.activeLink : styles.link}
            >
              {t(link.label)}
            </Link>
          ))}
        </div>
        <div className={styles.profileSection}>
          {showUserProfile && userInfo && userInfo.firstName ? (
            <div className={styles.dropdown} ref={divRef}>
              <UserProfile
                profilePictureData={userInfo.profilePictureData ? userInfo.profilePictureData : ''}
                initials={initials}
                isInteractive={false}
                small
              />
              <span className={styles.userName}>
                {userInfo.firstName} {userInfo.lastName}
              </span>
              <IconButton
                onClick={toggleDropdown}
                className={styles.dropdownOpen}
                style={{ transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              {isDropdownOpen && (
              <div className={styles.dropdownMenu}>
                <div className={styles.dropdownContent}>
                  <div className={styles.lastLogInInfo}>
                    <p>{decodeHtml(t('last-login', { lastLogin: formattedLoginDate }))}</p>
                  </div>
                  {dropdownMenuLinks.map((link, index) => (
                    <React.Fragment key={`${link.path}-${link.label}`}>
                      <p>
                        <Link
                          to={link.path}
                          style={{ color: link.color ? link.color : 'black' }}
                          className={styles.linkStyle}
                          onClick={toggleDropdown}
                        >
                          {link.icon && (
                            <div className={styles.customIconDiv}>
                              {typeof (link.icon) === 'string'
                                ? <img src={link.icon} alt="icon" className={styles.customSvgIcon} />
                                : <link.icon style={{ fontSize: '24px' }} className={styles.customIcon} />}
                            </div>
                          )}
                          {link.path === '/logout' && <ExitToAppIcon style={{ fontSize: '24px' }} className={styles.exitIcon} />}
                          {t(link.label)}
                        </Link>
                      </p>
                      {index < dropdownMenuLinks.length - 1
                          && <hr className={styles.dropdownDivider} />}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              )}
            </div>
          ) : (
          // If showUserProfile is false, and onSignIn function is provided,
          // optionally pass in button text and button function
            !showUserProfile && onSignIn && (
            // If the user is not authenticated, optionally pass in button text and function
            <Button text={signInButtonText} buttonStyle="cta-red-oval-small" onClick={onSignIn} />
            )
          )}
        </div>
      </div>
      <div className={errorBar ? styles.errorBar : styles.grayBar} />
    </div>
  )
}

BannerDesktop.propTypes = {
  userInfo: PropTypes.shape({
    profilePictureData: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    lastLogin: PropTypes.string,
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
  dropdownMenuLinks: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.Object
  })).isRequired,
  showUserProfile: PropTypes.bool,
  signInButtonText: PropTypes.string,
  onSignIn: PropTypes.func,
  errorBar: PropTypes.bool,
}

BannerDesktop.defaultProps = {
  userInfo: null,
  showUserProfile: false,
  signInButtonText: '',
  onSignIn: null,
  errorBar: false,
}

export default BannerDesktop
