import React, { useState, useEffect } from 'react'
import { IMaskInput } from 'react-imask'
import PropTypes from 'prop-types'
import Form from './Form'
import { formatPhoneToNational, convertPhoneToE164, validatePhoneNumber } from '../../util/phoneUtils'

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
// to eliminate prop spreading on the IMaskInput component, one would have to include
// at least 20 or 30 properties to pass in
// easier to just override the no prop spreading rule for such a trivial component
const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-0000"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      onChange={() => {}}
    />
  )
})

export const EditAccountManager = ({
  dispatch, userObj, t, updateUser
}) => {
  const [showEditAccount, setShowEditAccount] = useState(false)
  const [showProfileInfo, setShowProfileInfo] = useState(true)
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [userFields, setUserFields] = useState({
    firstName: userObj.firstName ?? '',
    lastName: userObj.lastName ?? '',
    email: userObj.email ?? '',
    secondEmail: userObj.secondEmail ?? '',
    mobilePhone: formatPhoneToNational(userObj.mobilePhone) ?? '',
  })
  const [validFields, setValidFields] = useState({
    firstName: { isValid: true, hasError: false },
    lastName: { isValid: true, hasError: false },
    email: { isValid: true, hasError: false },
    secondEmail: { isValid: true, hasError: false },
    mobilePhone: { isValid: true, hasError: false },
  })
  const [isFormValid, setIsFormValid] = useState(true)

  const toggleEditAccount = () => {
    setShowEditAccount(!showEditAccount)
    setShowProfileInfo(!showProfileInfo)

    setUserFields({
      firstName: userObj.firstName ?? '',
      lastName: userObj.lastName ?? '',
      email: userObj.email ?? '',
      secondEmail: userObj.secondEmail ?? '',
      mobilePhone: formatPhoneToNational(userObj.mobilePhone) ?? '',
    })

    setValidFields({
      firstName: { isValid: true, hasError: false },
      lastName: { isValid: true, hasError: false },
      email: { isValid: true, hasError: false },
      secondEmail: { isValid: true, hasError: false },
      mobilePhone: { isValid: true, hasError: false },
    })
  }

  const validateRegex = (field, newValue) => {
    const regexStrings = {
      email: /^(\S+@\S+\.\S+)$/,
      secondEmail: /^$|(\S+@\S+\.\S+)$/,
    }

    if (field === 'mobilePhone') {
      return validatePhoneNumber(newValue)
    }

    return regexStrings[field].test(newValue)
  }

  const validateChange = (field, newValue) => {
    setUserFields({ ...userFields, [field]: newValue })
    const nonRequiredFields = ['secondEmail', 'lastName', 'mobilePhone']
    if (nonRequiredFields.includes(field) && (!newValue || newValue.trim() === '')) {
      setValidFields((prev) => ({ ...prev, [field]: { isValid: true, hasError: false } }))
      return
    }
    const regexFields = ['email', 'secondEmail', 'mobilePhone']
    setValidFields((prev) => {
      if (regexFields.includes(field)) {
        const regexIsValid = validateRegex(field, newValue)
        return { ...prev, [field]: { isValid: regexIsValid, hasError: false } }
      }
      if (newValue && newValue.trim() !== '') {
        return { ...prev, [field]: { isValid: true, hasError: false } }
      }
      return { ...prev, [field]: { isValid: false, hasError: false } }
    })
  }

  const handleBlur = (field) => {
    if (!validFields[field].isValid) {
      setValidFields((prev) => ({ ...prev, [field]: { isValid: false, hasError: true } }))
    }
  }

  useEffect(() => {
    setIsFormValid(Object.values(validFields).every((field) => field.isValid))
  }, [validFields])

  const handleSubmit = () => {
    const formattedUserFields = {
      ...userFields,
      mobilePhone: convertPhoneToE164(userFields.mobilePhone),
    }

    dispatch(updateUser(formattedUserFields))
      .unwrap()
      .then(() => {
        setShowEditAccount(false)
      })
      .catch(() => {
        setAlertMessage(t('error-saveFailed'))
        setAlertVisible(true)
      })
  }

  const handleEditAccountError = (errorMessage) => {
    setAlertMessage(errorMessage)
    setAlertVisible(true)
  }

  const editAccountComponent = showEditAccount && (
    <Form
      t={t}
      userFields={userFields}
      validFields={validFields}
      validateChange={validateChange}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      isFormValid={isFormValid}
      TextMaskCustom={TextMaskCustom}
      onCancel={toggleEditAccount}
      onError={handleEditAccountError}
      setUserFields={setUserFields}
    />
  )

  return {
    editAccountComponent,
    setAlertVisible,
    showEditAccount,
    toggleEditAccount,
    handleSubmit,
    userFields,
    validFields,
    validateChange,
    handleBlur,
    isFormValid,
    TextMaskCustom,
    setUserFields,
    showProfileInfo
  }
}

TextMaskCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}
