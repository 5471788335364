import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { UserProfile } from '../user/UserProfile'
import { Button } from '../section-components/Button'
import styles from './Banner.module.scss'
import logo from '../../assets/JNJOneAccess-logo-red.svg'

function getInitials(userInfo) {
  if (!userInfo?.firstName) {
    return 'NN'
  }
  if (!userInfo.lastName) {
    return userInfo.firstName[0]
  }
  return `${userInfo.firstName[0]}${userInfo.lastName[0]}`
}

function BannerMobile({
  userInfo, showUserProfile, signInButtonText, onSignIn
}) {
  const initials = getInitials(userInfo)

  return (
    <div data-testid="banner" className={styles.mobileBanner}>
      <Link to="/home"><img src={logo} alt="J&J One Access" className={styles.mobileLogo} /> </Link>
      <div className={styles.mobileProfileSection}>
        {showUserProfile && userInfo && userInfo.firstName ? (
          <div>
            <UserProfile
              profilePictureData={userInfo.profilePictureData ? userInfo.profilePictureData : ''}
              initials={initials}
              isInteractive={false}
              small
            />
          </div>
        ) : (
        // If showUserProfile is false, and onSignIn function is provided,
        // optionally pass in button text and button function
          !showUserProfile && onSignIn && (
          // If the user is not authenticated, optionally pass in button text and function
          <div className={styles.smallButton}>
            <Button text={signInButtonText} buttonStyle="cta-red-oval-small" onClick={onSignIn} />
          </div>
          )
        )}
      </div>
      <div />
    </div>
  )
}
BannerMobile.propTypes = {
  userInfo: PropTypes.shape({
    profilePictureData: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  showUserProfile: PropTypes.bool,
  signInButtonText: PropTypes.string,
  onSignIn: PropTypes.func,
}

BannerMobile.defaultProps = {
  userInfo: null,
  showUserProfile: false,
  signInButtonText: '',
  onSignIn: null,
}

export default BannerMobile
